/**
 * Removes all accents and diacritics from a string and converts into lowercase
 * @param {string} string
 * @returns {string} - normalized string
 */
export const normalizeString = string =>
  string
    .normalize('NFD')
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '')

export function getCleanedString(string) {
  // Lo queremos devolver limpio en minusculas
  let cleanedString = string.toLowerCase()

  // Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
  cleanedString = cleanedString.replace(/á/gi, 'a')
  cleanedString = cleanedString.replace(/é/gi, 'e')
  cleanedString = cleanedString.replace(/í/gi, 'i')
  cleanedString = cleanedString.replace(/ó/gi, 'o')
  cleanedString = cleanedString.replace(/ú/gi, 'u')
  cleanedString = cleanedString.replace(/ñ/gi, 'n')
  return cleanedString
}

/* eslint-disable */
export function cleaned_title(title) {
  return title
    ? title
        .replace('-', '')
        .replace('|', '')
        .replace(/[^a-zA-Z0-9| ]/, '')
        .toLowerCase()
    : ''
}

export function search_action_type(place_value) {
  if (place_value === null) return ''
  let action = 'destination'
  switch (place_value.charAt(0)) {
    case 's':
      action = 'specialty'
      break
    case 'h':
      action = 'hotel'
      break
    case 'd':
      action = 'destination'
      break
  }
  return action
}

export function cloneJson(data) {
  return JSON.parse(JSON.stringify(data))
}

/* eslint-disable */
// Vanilla js extend from http://youmightnotneedjquery.com/
export function extend(out) {
  out = out || {};

  for (let i = 1; i < arguments.length; i++) {
    if (!arguments[i]) {continue;}

    for (let key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {out[key] = arguments[i][key];}
    }
  }

  return out;
}

/**
 * Función recursiva que busca un objeto con una propiedad específica y un valor específico dentro de un
 * array de objetos anidados.
 *
 * @param {array} array El array de objetos en el que se busca el objeto.
 * @param {string} propiedad El nombre de la propiedad que se busca en el objeto.
 * @param {string} valor El valor de la propiedad que se busca.
 *
 * @returns {object|null} El objeto encontrado, o `null` si no se encontró el objeto.
 */
export function findChildrenInNestedArray(array, property, value) {
  // Si el array está vacío, devolver null
  if (array.length === 0) {
    return null
  }

  // Si el primer elemento del array tiene la propiedad y el valor coincide, devolver el elemento
  if (array[0].hasOwnProperty(property) && array[0][property] === value) {
    return array[0]
  }

  // Si el primer elemento del array no tiene la propiedad o el valor no coincide, buscar el objeto en los hijos del elemento
  for (const children of array[0].children) {
    const result = findChildrenInNestedArray([children], property, value)
    if (result) {
      return result
    }
  }

  // Si no se encontró el objeto en el primer elemento del array, buscar el objeto en el resto del array
  const arraySliced = array.slice(1)
  return findChildrenInNestedArray(arraySliced, property, value)
}
